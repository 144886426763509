import { useEffect, useRef } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import ProductsGrid from 'components/productsGrid';
import ProductsSlider from 'components/productsSlider';

import { parseProductsEcommerceGA4 } from 'utils/gtmUtils';
import { dataLayerHandleEvent } from 'utils/dataLayers';

import styles from './style.module.css';

const ProductsRow = ({
  locale,
  title,
  source,
  slider,
  currentList,
  camperlab,
  withHover,
  fixedGrid,
  slidesToShow,
  forcedSettings,
  withPlaceholder,
  loading,
  squareImg,
  addToBagButton,
  withLabel,
  goToStartOnChange = false,
}) => {
  const initDataLayer = useRef(false);
  console.log('render products row');

  useEffect(() => {
    // metemos un view_item_list siempre que se monte products row
    try {
      if (source.length > 0 && initDataLayer.current === false) {
        const [firstProduct] = source;
        const { currency } = firstProduct.prices;

        const eventLayerGA4 = {
          event: 'view_item_list',
          nonInteraction: '1',
          ecommerce: {
            currency,
            items: parseProductsEcommerceGA4(source, currentList, currentList, 0),
          },
        };

        dataLayerHandleEvent(eventLayerGA4);
        initDataLayer.current = true;
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (source.length > 0) {
    if (slider) {
      return (
        <>
          {title && (
            <h2 className={styles.title} data-trackingga4={`${currentList?.toLowerCase()}|slider`}>
              {title}
            </h2>
          )}
          <ProductsSlider
            locale={locale}
            products={source}
            currentList={currentList}
            withLabel={withLabel}
            camperlab={camperlab}
            fixedGrid={fixedGrid}
            slidesToShow={slidesToShow}
            forcedSettings={forcedSettings}
            withPlaceholder={withPlaceholder}
            loading={loading}
            withHover={withHover}
            squareImg={squareImg}
            addToBagButton={addToBagButton}
            goToStartOnChange={goToStartOnChange}
          />
        </>
      );
    }
    return (
      <>
        {title && <h2 className={styles.title}>{title}</h2>}
        <Row className={styles.sectionRowGrid}>
          <ProductsGrid
            locale={locale}
            products={source}
            currentList={currentList}
            withLabel={withLabel}
            camperlab={camperlab}
            withHover={withHover}
            currentListName={title}
            squareImg={squareImg}
          />
        </Row>
      </>
    );
  }
  return null;
};

ProductsRow.propTypes = {
  locale: PropTypes.string,
  title: PropTypes.string.isRequired,
  source: PropTypes.array.isRequired,
  currentList: PropTypes.string,
  slider: PropTypes.bool,
  camperlab: PropTypes.bool,
  withHover: PropTypes.bool,
  fixedGrid: PropTypes.bool,
  slidesToShow: PropTypes.string,
  forcedSettings: PropTypes.object,
  withPlaceholder: PropTypes.bool,
  loading: PropTypes.any,
  squareImg: PropTypes.bool,
  withLabel: PropTypes.bool,
  addToBagButton: PropTypes.bool,
  goToStartOnChange: PropTypes.bool,
};

ProductsRow.defaultProps = {
  slider: false,
  camperlab: false,
  fixedGrid: true,
  currentList: '',
  slidesToShow: undefined,
  loading: 'lazy',
  squareImg: false,
  addToBagButton: false,
  withLabel: true,
};

export default ProductsRow;
